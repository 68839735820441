import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex, SpacerDesktop, SpacerMobile, SpringConfig } from '../../../styles/index'
import InputForm from './InputForm'
import Button from '../../shared/Button'
import {animated, useSpring} from 'react-spring'
import { useGlobalDictionaryQuery, useLoginCustomer } from '../../../hooks'
import LinkTool from '../../shared/LinkTool'
import { navigate } from 'gatsby'
import { useLocale } from '../../../hooks/layout/useLocale'


export default function LoginForm(props: Props) {


  const [loginCustomer, { response,loading, error }] = useLoginCustomer()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [containErrors, SetContainErrors] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [text, setText] = useState('')

  const locale = useLocale();
  const dictionary = useGlobalDictionaryQuery();

  const reset = ()=>{setClicked(false); setEmail(''); }
  const softReset = ()=>{setClicked(false) }

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true)
    }
    if (!state) {
      SetContainErrors(false)
    }
  }

  const submitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await loginCustomer(email, password)
  }

  useEffect(() => {
    if(error && !clicked){
      setClicked(true)
      setText(error)
      setTimeout(()=>{
        softReset()
      }, 5000)
    }
    if (response) {
      navigate(`/${locale}/account/`);
    }
  }, [response, error])

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction
  })

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(350%)',
    config: SpringConfig.microInteraction
  })

  return (
    <Wrapper onSubmit={(e) => submitEmail(e)}>
      <PreTitle>{dictionary.account}</PreTitle>
      <Title>Login</Title>
      <SpacerDesktop vertical={true} sHeight={35} />
      <SpacerMobile vertical={true} sHeight={20} />
      <InputForm
        inputValue={email}
        inputSetValue={setEmail}
        inputName="email"
        inputType="text"
        required={true}
        onChangecallback={(e) => setEmail(e.target.value)}
        placeholder={dictionary.emailAddress}
        color={colors.black}
        width={"100%"}
        widthDesk={"100%"}
        fontSizeDesk={desktopVW(16)}
        fontSize={mobileVW(16)}
        validation={"onlyEmail"}
        setContainErrors={handleContainerrors}
      />
      <SpacerDesktop vertical={true} sHeight={20} />
      <SpacerMobile vertical={true} sHeight={20} />
      <InputForm
        inputValue={password}
        inputSetValue={setPassword}
        inputName="password"
        inputType="password"
        required={true}
        onChangecallback={(e) => setPassword(e.target.value)}
        placeholder={dictionary.password}
        color={colors.black}
        width={"100%"}
        widthDesk={"100%"}
        fontSizeDesk={desktopVW(16)}
        fontSize={mobileVW(16)}
        validation={"noValidation"}
        setContainErrors={handleContainerrors}
      />
      <SpacerDesktop vertical={true} sHeight={20} />
      <SpacerMobile vertical={true} sHeight={23} />
      <OverflowHidden>
        <animated.div style={{ ...buttonSpring }}>
          <Button
            text={loading ? dictionary?.loading : dictionary?.send}
            Type="submit"
            AriaLabel={"Send"}
            width="100%"
            widthDesk="100%"
            colorBackground={"black"}
            color="white"
            disableIt={containErrors}
          />
        </animated.div>
        <ResponseMsg style={{ ...responseSpring }}>
          <p>{text}</p>
        </ResponseMsg>
      </OverflowHidden>
      <LoginFooter>
        <p>
          <LinkTool to="/account/register">{dictionary.createAnAccount}</LinkTool>
        </p>
        <p>
          <LinkTool to="/account/forgot">{dictionary.forgotMyPassword}</LinkTool>
        </p>
      </LoginFooter>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: auto;
  width: 90vw;
  top: 50vh;
  left: 50vw;
  z-index: ${zIndex.surface};
  background-color: ${colors.frappuccino};
  transform: translate(-50%, -50%);
  padding: ${mobileVW(46)} ${mobileVW(35)} ${mobileVW(50)} ${mobileVW(35)};
  h1{
    font-family: 'messinaMono';
    font-size: ${mobileVW(30)};
    line-height: ${mobileVW(30)};
    padding: ${mobileVW(8)} 0 ${mobileVW(10)} 0;
  }
  ${desktopBreakpoint}{
    top: ${desktopVW(205)};
    left: ${desktopVW(862)};
    width: ${desktopVW(432)};
    padding: ${desktopVW(46)} ${desktopVW(60)} ${desktopVW(60)} ${desktopVW(60)};
    transform: translate(0%, 0%);
    h1{
      font-size: ${desktopVW(35)};
      line-height: ${desktopVW(35)};
      padding: ${desktopVW(8)} 0 ${desktopVW(10)} 0;
    }
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;
  ${desktopBreakpoint}{
  
  }
`;

const ResponseMsg = styled(animated.div)`
  transform: translateY(350%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  p{
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(14)};
    text-align: center;
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
    }
  }

  ${desktopBreakpoint}{
    top: ${desktopVW(20)};
  }
`;

const PreTitle = styled.p`
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(12)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14)};
  }
`;

const Title = styled.p`
  font-family: 'messinaMonoLight';
  font-size: ${mobileVW(12)};
  color: ${colors.black};
  font-size: ${mobileVW(30)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(30)};
    line-height: 80%; 
  }
`;

const LoginFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${mobileVW(30)};
  padding-top: ${mobileVW(20)};

  p{
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(12)};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(12)};
      line-height: 80%; 
      padding: 0 5px;
    }
  }

  ${desktopBreakpoint}{
    height: ${desktopVW(35)};
    padding-top: ${desktopVW(25)};
  }
`;


//   const submitEmail = async e => {
//     e.preventDefault()
//     setLoading(true)
//     setTimeout(()=>{
//       loginCustomer(email, password).then(response =>{
//         console.log(response)
//         setClicked(true)
//         // if(response.success){
//         //   if(response.data.is_subscribed){
//         //     setText(emailSubscribeAlreadyInListMsg)
//         //   } else {
//         //     setText(emailSubscribeSuccessMsg)
//         //   }
//         //   setTimeout(()=>reset(), 5000)
//         // } else {
//         //   setText(emailSubscribeFailMsg)
//         //   setTimeout(()=>reset(), 5000)
//         // }
//       })
//     }, 500)
// }