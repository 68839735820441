import React, { useContext } from 'react';
import styled from 'styled-components';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import { StoreContext } from '../contexts';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex, colors } from '../styles/index';
import AuthWrapper from '../components/account/authWrapper';
// @ts-ignore
import { Activate } from '../components/account/activate';

import { ForgotPassword } from '../components/account/forgotPassword';
import { Register } from '../components/account/register';
import { Login } from '../components/account/login';
import { Reset } from '../components/account/reset';
import { InvalidToken } from '../components/account/invalid_token';
import { Portal } from '../components/account/portal';
import { useLocale } from '../hooks/layout/useLocale';

export interface accountProps {
  data: {
    contentfulPageAccount: {
      title: string;
      loginBackgroundMobile: {
        node_locale: string;
        title: string;
        fluid: string;
      };
      loginBackgroundDesktop: {
        node_locale: string;
        title: string;
        fluid: string;
      };
    };
  };
}

const Account = ({ data: { contentfulPageAccount } }: accountProps) => {
  const { title, loginBackgroundMobile, loginBackgroundDesktop } = contentfulPageAccount;

  const currLocale = useLocale();

  return (
    <>
      <Wrapper>
        <Router>
          <PublicRoute path={`/${currLocale}/account`}>
            <AuthWrapper path="/" component={Portal} />
            <Login
              path="/login"
              loginBackgroundMobile={loginBackgroundMobile}
              loginBackgroundDesktop={loginBackgroundDesktop}
            />
            <Reset
              path="/reset/:id/:token"
              id={typeof window === 'object' ? location.pathname.split('/')[4] : ''}
              token={typeof window === 'object' ? location.pathname.split('/')[5] : ''}
              loginBackgroundMobile={loginBackgroundMobile}
              loginBackgroundDesktop={loginBackgroundDesktop}
            />
            <Activate
              // @ts-ignore
              path="/activate/:id/:token"
              id={typeof window === 'object' ? location.pathname.split('/')[4] : ''}
              token={typeof window === 'object' ? location.pathname.split('/')[5] : ''}
              loginBackgroundMobile={loginBackgroundMobile}
              loginBackgroundDesktop={loginBackgroundDesktop}
            />
            <InvalidToken path="/invalid_token" />
            <Register
              path="/register"
              loginBackgroundMobile={loginBackgroundMobile}
              loginBackgroundDesktop={loginBackgroundDesktop}
            />
            <ForgotPassword
              path="/forgot"
              loginBackgroundMobile={loginBackgroundMobile}
              loginBackgroundDesktop={loginBackgroundDesktop}
            />
          </PublicRoute>
        </Router>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;

  ${desktopBreakpoint} {
  }
`;

function PublicRoute(props: { children: React.ReactNode; path: string }) {
  return <div>{props.children}</div>;
}

export default Account;

export const AccountPageQuery = graphql`
  query AccountPage($id: String!) {
    contentfulPageAccount(id: { eq: $id }) {
      node_locale
      title
      loginBackgroundMobile {
        node_locale
        title
        fluid(maxWidth: 800, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      loginBackgroundDesktop {
        node_locale
        title
        fluid(maxWidth: 1800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
