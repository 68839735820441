import React, {useState} from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../../styles/index'
import formatDate from '../../../utils/formatDate'
import formatPrice from '../../../utils/formatPrice'
import LinkTool from '../../shared/LinkTool'
import {Portal} from '../../shared/Portal'
import ReturnForm from '../accountForms/ReturnForm'
import OrderCard from '../accountComponents/OrderCard'
import { useGlobalDictionaryQuery } from '../../../hooks'

interface Props {
  user: {}
}

export default function MyOrders(props: Props) {

  const {
    user, 
  } = props

  const [isReturnFormOpen, setReturnFormOpen] = useState(false)
  const [toReturn, setToReturn] = useState({
    order: {},
    itemToReturn: {}
  })

  const dictionary = useGlobalDictionaryQuery();

  const openReturnForm = ()=> setReturnFormOpen(true)
  const closeReturnForm = ()=> setReturnFormOpen(false)
  const setToOrderReturn = (order, itemToReturn) => setToReturn({order, itemToReturn})

  return (
    <>
    {isReturnFormOpen && (
      <ReturnFormWrapper>
        <ReturnBackground 
          onClick={()=>closeReturnForm()}
        />
        <ReturnForm 
          toReturn={toReturn}
          user={user}
          closeReturnForm={closeReturnForm}
        />
      </ReturnFormWrapper>
    )}
    <Wrapper>
      <SectionHeader>
        <Text>
          <h2>
            {dictionary.myOrders}
          </h2>
          <p>
            {dictionary.pleaseFindHereAllYourOrders}
          </p>
        </Text>
      </SectionHeader>
      {
        user?.orders?.edges?.length > 0
        ?
        <SectionContent>
          {
            user.orders.edges
            .sort((a, b)=> b.node.orderNumber - a.node.orderNumber)
            .map(({node}, index)=>(
              <OrderCard 
                order={node}
                key={index}
                index={index + 1}
                openReturnForm={openReturnForm}
                closeReturnForm={closeReturnForm}
                setToOrderReturn={setToOrderReturn}
              />
            ))
          }
        </SectionContent>
        :
        null
      }
    </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0;

  ${desktopBreakpoint}{
    padding: 0 ${desktopVW(120)} 0 ${desktopVW(60)};
  }
`;


const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${desktopBreakpoint}{
    flex-direction: row;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(35)};
    color: ${colors.black};
    line-height: 110%; 
    font-weight: 700;
    padding: ${mobileVW(30)} 0 0 0;
    text-align: center;

    ${desktopBreakpoint}{
      line-height: 105%; 
      font-size: ${desktopVW(30)};
      padding: 0 0 ${desktopVW(19)} 0;
      text-align: left;
    }
  }

  p{
    display: block;
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.subtleGrey};
    width: 100%;
    text-align: center;
    padding: ${mobileVW(30)} ${mobileVW(40)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      text-align: left;
    }
  }

  ${desktopBreakpoint}{
  
  }
`;

const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: ${mobileVW(30)};

  ${desktopBreakpoint}{
    width: ${desktopVW(840)};
    padding-top: ${desktopVW(66)};
  }
`;

const ReturnFormWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.satan};

  ${desktopBreakpoint}{
  
  }
`;

const ReturnBackground  = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${zIndex.satan};
  cursor: pointer;

  ${desktopBreakpoint}{
  
  }
`;


