/* eslint-disable  */ 
/* eslint-disable-line no-console  */
/* eslint-disable no-console */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex, SpringConfig } from '../../../styles/index'
import formatPrice from '../../../utils/formatPrice'
import {animated, useSpring} from 'react-spring'
import Button from '../../shared/Button'
import { useGlobalDictionaryQuery } from '../../../hooks'

interface Props {
  toReturn: {}
  user: {}
  closeReturnForm: ()=>void
}

export default function ReturnForm(props: Props) {

  const {
    toReturn,
    user,
    closeReturnForm,
  } = props

  const dictionary = useGlobalDictionaryQuery()

  const [quantityToReturn, setQuantityToReturn] = useState(1)
  const [selectedReturnReason, setSelectedReturnreason] = useState('...')
  const [userCustomReturnReason, setUserCustomReturnReason] = useState('')
  const [returnReasontoSend, setReturnreasonToSend] = useState('')
  const [showTextField, setShowTextField] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [containErrors, SetContainErrors] = useState(false)

  const returnReasons = [
    '...',
    'Defected',
    'Wrong article',
    'Transport damage',
    "Doesn't meet the expectation",
    'Wrongly ordered',
    "Other",
  ]

  const emailJsParams = {
    user_id: 'user_L3ssUJY9M9aqJENuNu4Rm',
    service_id: 'Returns',
    template_id: 'template_njgnx5e',
    template_params: {
      'from_name': `${user.firstName} ${user.lastName}`,
      'from_email': user.email,
      'orderNumber': toReturn.order.orderNumber,
      'productTitle': toReturn.itemToReturn.title,
      'productVariant': toReturn.itemToReturn.variant.title,
      'quantity': quantityToReturn,
      'message': returnReasontoSend,
    }
  }

  const emailJsHeaders = {
    'Content-type': 'application/json'
  }

  const emailJsOptions = {
    method: 'POST',
    headers: emailJsHeaders,
    body: JSON.stringify(emailJsParams)
  }

  const handleChange = (e) => {
    setReturnreasonToSend(e.target.value)
  }

  const handleSubmit = async (e)=>{
    e.preventDefault()
    setClicked(true)
    setText('Loading...')
    const getId = toReturn.order.id?.replace('gid://shopify/Order/', '');
    const id = getId?.split('?key=');

    const tempResponse = await fetch('/.netlify/functions/getOrderData', {
      method: 'POST',
      body: id[0],
    });
    const response = await tempResponse.json()
    if(response.refunds){
      //  ***  Order has been already refunded
      setText(dictionary?.accountReturnAlreadyRefunded);
    } else {      
      const orderDateTemp = response.fulfillment.split("T")[0]
      const formatShopifyDate = str =>{
        const tempArray = str.split("-")
        return `${tempArray[1]}/${tempArray[2]}/${tempArray[0]}`
      }
      const todayFormatted = ()=>{
        let today = new Date()
        return `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`
      }
      const dateDiff = (date1, date2)=> {
        const diffInMs   = new Date(date1) - new Date(date2)
        return diffInMs / (1000 * 60 * 60 * 24);
      }
      if(dateDiff(todayFormatted(),formatShopifyDate(orderDateTemp)) > 19){
        //  ***  Order is older than 14 days
        setText(dictionary?.accountReturnPeriodExpired);
      } else {
        try {
          //  ***  Send an email to d-bodhi with order data and refund req.
          const everything = await fetch('https://api.emailjs.com/api/v1.0/email/send', emailJsOptions)
          if(everything.ok){
            setText(dictionary?.accountReturnSuccess);
          } else {
            setText(dictionary?.accountReturnFail);
          }
        } catch (err) {
          setText(dictionary?.accountReturnFail);
          console.log(err)
        }
      }
    }
  }

  const updateQuantity = (mode, state, setter, limit) =>{
    if(mode === 'add'){
      if(state + 1 <= limit){
        setter(state + 1)
      } else {
        return
      }
    } else if (mode == 'remove'){
      if (state - 1 > 0){
        setter(state - 1)
      } else {
        return
      }
    }
  }

  useEffect(() => {
    if(selectedReturnReason.toLowerCase() === 'other'){
      setShowTextField(true)
      setReturnreasonToSend(userCustomReturnReason)
    } else {
      setShowTextField(false)
      SetContainErrors(false)
      setReturnreasonToSend(selectedReturnReason)
    }
  }, [selectedReturnReason])

  useEffect(() => {
    if(returnReasontoSend === ''){
      SetContainErrors(true)
      return
    } else if (returnReasontoSend === '...') {
      SetContainErrors(true)
      return
    } else {
      SetContainErrors(false)
      return
    }
  }, [returnReasontoSend])

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction
  })

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(350%)',
    config: SpringConfig.microInteraction
  })

  return (
    <Wrapper
      onSubmit={e=>handleSubmit(e)}
    >
      <ReturnHeader>
        <h2>
          Return your product
        </h2>
        <p>
          Order N°:
          <span className="ReturnFormHeaderTextClear">{toReturn.order.orderNumber}</span>
        </p>
        <CloseButton
          onClick={()=> closeReturnForm()}
        >
          X
        </CloseButton>
      </ReturnHeader>
      <ReturnBody>
        <OrderItemWrapper
        >
          <OrderItemImg>
            <img 
              src={toReturn.itemToReturn.variant.image.originalSrc}
            />
          </OrderItemImg>
          <OrderItemContent>
            <OrderItemTitle>
              <p>{toReturn.itemToReturn.title}</p>
              <p className="orderItemTitle__paddingTop">{toReturn.itemToReturn.variant.title}</p>
            </OrderItemTitle>
            <OrderItemPrice>
              {formatPrice(Number(toReturn.itemToReturn.variant.price), "IDR")}
            </OrderItemPrice>
            <OrderItemQuantity>
              <p
                className="orderItemTitle__quantityTitle"
              >Quantity to be returned</p>
              <OrderItemQuantityGrid>
                <div
                  className="orderItem__quantityItem orderItem__gridLeft"
                  onClick={()=>updateQuantity('remove', quantityToReturn, setQuantityToReturn, toReturn.itemToReturn.quantity)}
                >-</div>
                <div
                  className="orderItem__quantityItem"
                >{quantityToReturn}</div>
                <div
                  className="orderItem__quantityItem orderItem__gridRight"
                  onClick={()=>updateQuantity('add', quantityToReturn, setQuantityToReturn, toReturn.itemToReturn.quantity)}
                >+</div>
              </OrderItemQuantityGrid>
            </OrderItemQuantity>
          </OrderItemContent>
        </OrderItemWrapper>
      </ReturnBody>
      <ReturnFooter
        
      >
        <ReturnReason
          containErrors={containErrors}
        >
          <p>
            Please select the return reason:
          </p>
          <select 
            value={selectedReturnReason}
            onChange={e=>setSelectedReturnreason(e.target.value)}
          >
            {
              returnReasons.map(itm=>(
                <option 
                  value={itm}
                  key={itm}
                >
                  {itm}
                </option>
              ))
            }
          </select>

        </ReturnReason>
        {
          showTextField
          ?
          <ReturnInput
            type="textarea" 
            name="textValue"
            onChange={handleChange}
            placeholder="Specify return reason"
            containErrors={containErrors}
            autocomplete="off"
          />
          : 
          null
        }
        <ButtonWrapper>
          <OverflowHidden>
            <animated.div
              style={{...buttonSpring}}
            >
              <button
                type="submit"
                aria-label="send return enquiry"
                className="returnFormSend"
                //onClick={()=>handleSubmit()}
                disabled={containErrors}
              >
                Send!
              </button>
            </animated.div>
            <ResponseMsg
            style={{...responseSpring}}
            >
              <p>
                {text}
              </p>
            </ResponseMsg>
          </OverflowHidden>
        </ButtonWrapper>
      </ReturnFooter>

    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
  background-color: white;
  z-index: ${zIndex.satan};
  width: 100%;
  
  ${desktopBreakpoint}{
    width: ${desktopVW(650)};  
    height: auto;

  }
`;

const ReturnHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  h2{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(30)};
    color: ${colors.black};
    line-height: 110%; 
    font-weight: 700;
    text-align: center;
    padding: ${mobileVW(30)} 0 0 0 ;

    ${desktopBreakpoint}{
      line-height: 105%; 
      font-size: ${desktopVW(25)};
      text-align: left;
      padding: 0;
    }
  }
  
  p{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.black};
    text-align: center;
    padding: ${mobileVW(30)} 0;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(5)} 0 ${desktopVW(6)} 0;
      text-align: left;
    }
  }

  .ReturnFormHeaderTextClear{
    color: ${colors.subtleGrey};
    margin-left: 1ch;
  }

  ${desktopBreakpoint}{
    flex-direction: row;
    border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.3);  
    padding: ${desktopVW(30)} ${desktopVW(40)} ${desktopVW(30)} ${desktopVW(20)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  font-family: 'messinaMonoLight';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.black};
  text-align: center;
  top: ${mobileVW(10)};
  right: ${mobileVW(10)};

  ${desktopBreakpoint}{
    top: ${desktopVW(15)};
    right: ${desktopVW(15)};  
    font-size: ${desktopVW(14)};
    line-height: 80%; 
    text-align: left;
  }

`;

const ReturnBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint}{
    padding: 0 ${desktopVW(20)};
  }
`;

const OrderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${mobileVW(187)};
  ${({isLast}) => isLast ? 'border-bottom: none;' : `border-bottom: ${mobileVW(2)} solid rgba(0, 0, 0, 0.1);`}
  padding: ${mobileVW(26)} 0;

  ${desktopBreakpoint}{
    height: ${desktopVW(210)};
    ${({isLast}) => isLast ? 'border-bottom: none;' : `border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);`}
    padding: ${desktopVW(30)} 0;
  }
`;

const OrderItemImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${mobileVW(135)};
  width: ${mobileVW(140)};
  overflow: hidden;

  
  img{
    height: 100%;
    width: auto;
  }

  ${desktopBreakpoint}{
    width: ${desktopVW(140)};
    height: 100%;
  }
`;

const OrderItemContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${desktopBreakpoint}{
    
  }
`;

const OrderItemTitle = styled.div`
  position: absolute;
  top: 0;
  left: ${mobileVW(15)};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};
  display: flex;
  flex-direction: column;
  font-size: ${mobileVW(12)};
  width: 70%;

  .OrderItemTitle__paddingTop{
    color: ${colors.subtleGrey};
    padding-top: ${mobileVW(7)};
    ${desktopBreakpoint}{
      padding-top: ${desktopVW(5)};  
    }
  }

  ${desktopBreakpoint}{
    font-size: ${desktopVW(15)};
    left: ${desktopVW(24)};
  }
`;

const OrderItemPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(15)};
  }
`;

const OrderItemQuantity = styled.div`
  position:  absolute;
  bottom: 0;
  left: ${mobileVW(15)};
  display: flex;
  flex-direction: row;
  height: ${mobileVW(20)};

  .orderItemTitle__quantityTitle{
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    height: 100%;
    text-align: center;
    color: ${colors.subtleGrey};
    font-size: ${mobileVW(12)};
    padding: ${mobileVW(2)} ${mobileVW(10)} 0 0;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(15)};
      padding: ${desktopVW(3)} ${desktopVW(15)} 0 0;
    }
  }

  ${desktopBreakpoint}{
    height: ${desktopVW(27)};
    left: ${desktopVW(24)};
  }
`;

const OrderItemQuantityGrid = styled.div`
    display: grid;
    color: ${colors.subtleGrey};
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    user-select: none;
    grid-template-columns: 30% 40% 30%;
    width: ${mobileVW(70)};
    border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};

  .orderItem__quantityItem{
    font-size: ${mobileVW(12)};
    color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};
    display: flex;
    align-items: center;
    justify-content: center;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(13)}; 
    }
  }

  .orderItem__gridLeft{
    border-right: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  .orderItem__gridRight{
    border-left: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  ${desktopBreakpoint}{
    border: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    width: ${desktopVW(83)};
  }
`;

const ReturnFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${desktopBreakpoint}{
    height: auto;
    padding: 0 ${desktopVW(20)};
  }
`;

const ReturnInput = styled.input`
  width: 80%;
  border: none;
  font-family: 'messinaMonoLight';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.black};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  ${({containErrors})=> 
      containErrors 
      ? 
      `border: ${mobileVW(1)} solid ${colors.tomato};` 
      : 
      `border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};`
    }
  margin: ${mobileVW(30)} 0 0 10%; 


  ${desktopBreakpoint}{
    width: auto;
    height: auto;
    font-size: ${desktopVW(14)};
    ${({containErrors})=> 
      containErrors 
      ? 
      `border: ${desktopVW(1)} solid ${colors.tomato};` 
      : 
      `border: ${desktopVW(1)} solid ${colors.lightGreyButLighter};`
    }
    margin: ${desktopVW(20)} 0 0 0 ;
  }
`;

const ReturnReason = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  p{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.black};
    text-align: left;
    padding: ${mobileVW(30)} ${mobileVW(20)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(10)} 0 ;
      
    }
  }

  select{
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.black};
    cursor: pointer;
    outline: none;
    margin-left: ${mobileVW(20)};
    background-color: transparent;
    border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};
    width: 90%;
    padding: ${mobileVW(10)} 0;

    ${({containErrors})=> 
      containErrors 
      ? 
      `border: ${mobileVW(1)} solid ${colors.tomato};`
      :
      `border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};`
    }

    ${desktopBreakpoint}{
      height: auto;
      font-size: ${desktopVW(14)};
      margin-left: 1ch;
      padding: 0 1ch 0 0;
      border-radius: ${desktopVW(3)};
      width: auto;
      ${({containErrors})=> 
        containErrors 
        ? 
        `border: ${desktopVW(1)} solid ${colors.tomato};`
        :
        `border: ${desktopVW(1)} solid ${colors.lightGreyButLighter};`
      }
    }
  }

  ${desktopBreakpoint}{
    flex-direction: row;
    padding: ${desktopVW(30)} 0 0 0 ;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  .returnFormSend{
    width: ${mobileVW(250)};
    height: ${mobileVW(70)};
    background-color: ${colors.black};
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};
    color: ${colors.white};
    text-align: center;
    padding: ${mobileVW(30)} ${mobileVW(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${mobileVW(30)} 0;

    ${desktopBreakpoint}{
      width: ${desktopVW(250)};
      height: ${desktopVW(70)};
      font-size: ${desktopVW(14)};
      line-height: 80%; 
      padding: ${desktopVW(10)} 0 ;
      text-align: left;
      margin: 0;
    }
  }

  ${desktopBreakpoint}{
    padding: ${desktopVW(30)} 0;
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;
  ${desktopBreakpoint}{
  
  }
`;

const ResponseMsg = styled(animated.div)`
  transform: translateY(350%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  p{
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(14)};
    text-align: center;
    line-height: 140%; 

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint}{
    top: ${desktopVW(10)};
  }
`;
